var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-layout',{staticClass:"headline mb-1"},[_c('img',{attrs:{"src":_vm.schedulePic,"height":"80px"}}),_c('div',{staticClass:"pl-4 pt-4"},[_vm._v(" ĐĂNG KÝ XIN NGHỈ "),_c('v-list-item-subtitle',[_vm._v("Danh sách ngày đăng ký nghỉ của nhân viên")])],1)])],1),_c('v-btn',{attrs:{"color":"#33691E"},on:{"click":_vm.create}},[_c('span',{staticClass:"btn-add",staticStyle:{"color":"white"}},[_vm._v("Đăng ký nghỉ")])])],1)],1),_c('br'),_c('v-card',{staticClass:"mb-1"},[_c('v-card-title',{staticClass:"pa-6 pb-4"},[_c('div',{staticClass:"mr-4",staticStyle:{"width":"90%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":_vm.phongBans,"placeholder":"Phòng ban","hide-details":"","clearable":"","prepend-inner-icon":"mdi-city"},on:{"change":_vm.searchData},model:{value:(_vm.phong_ban_id),callback:function ($$v) {_vm.phong_ban_id=$$v},expression:"phong_ban_id"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","clearable":"","placeholder":"Chọn từ ngày đến ngày"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDate),callback:function ($$v) {_vm.showDate=$$v},expression:"showDate"}},[_c('v-date-picker',{attrs:{"no-title":"","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showDate = false}}},[_vm._v(" Hủy ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!this.date || this.date.length != 2},on:{"click":function($event){return _vm.changeDate()}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.nguoi_gui.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.nguoi_gui.url_image,"alt":"ManhLe"}}):(item && item.nguoi_gui.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.nguoi_gui.name.charAt(0).toUpperCase()))]):_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_c('span',{staticClass:"ml-3",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.nguoi_gui.name))])]}},{key:"item.thoi_gian_nghi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.bat_dau))+" - "+_vm._s(_vm.formatDate(item.ket_thuc))+" ")]}},{key:"item.trang_thai",fn:function(ref){
var item = ref.item;
return [(
          _vm.TRANG_THAI.CHO_DUYET == item.trang_thai &&
          new Date(item.ket_thuc) >= Date.now()
        )?_c('v-chip',{attrs:{"color":"primary","dark":"","small":""}},[_vm._v(" Chờ duyệt ")]):_vm._e(),(_vm.TRANG_THAI.TU_CHOI == item.trang_thai)?_c('v-chip',{attrs:{"color":"pink","dark":"","small":""}},[_vm._v(" Từ chối ")]):_vm._e(),(_vm.TRANG_THAI.DA_DUYET == item.trang_thai)?_c('v-chip',{attrs:{"color":"success","dark":"","small":""}},[_vm._v(" Được phê duyệt ")]):_vm._e(),(
          _vm.TRANG_THAI.CHO_DUYET == item.trang_thai &&
          new Date(item.ket_thuc) <= Date.now()
        )?_c('v-chip',{attrs:{"color":"orange","dark":"","small":""}},[_vm._v(" Hết hạn ")]):_vm._e()]}},{key:"item.loai",fn:function(ref){
        var item = ref.item;
return [(
          'KHONG_PHEP' == item.loai
        )?_c('v-chip',{attrs:{"color":"primary","dark":"","small":"","outlined":""}},[_vm._v(" Không cắt phép ")]):_vm._e(),('CO_PHEP' == item.loai)?_c('v-chip',{attrs:{"color":"success","dark":"","small":"","outlined":""}},[_vm._v(" Nghỉ cắt phép ")]):_vm._e(),('NGHI_LE' == item.loai)?_c('v-chip',{attrs:{"color":"orange","dark":"","small":""}},[_vm._v(" Nghỉ lễ ")]):_vm._e()]}},{key:"item.nguoi_duyet",fn:function(ref){
        var item = ref.item;
return [(item.nguoi_duyet)?_c('div',[_c('v-avatar',{attrs:{"color":"indigo","size":"28"}},[(item.nguoi_duyet && item.nguoi_duyet.url_image)?_c('img',{attrs:{"src":_vm.imageEndpoint + item.nguoi_duyet.url_image,"alt":"ManhLe"}}):(item.nguoi_duyet && item.nguoi_duyet.name)?_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.nguoi_duyet.name.charAt(0).toUpperCase()))]):_vm._e()]),(item.nguoi_duyet)?_c('span',{staticClass:"ml-3",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(item.nguoi_duyet.name))]):_vm._e()],1):_vm._e()]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Chi tiết")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.capNhat(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Sửa đổi")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.xoaLichNghi(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa lịch nghỉ")],1)],1)],1)],1)]}}],null,true)})],1),_c('div',{staticClass:"pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_vm._v(">")])],1),_c('show-info',{ref:"thaisan",on:{"on-done":_vm.getData}}),_c('create-edit',{ref:"dangky",on:{"on-done":_vm.getData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }