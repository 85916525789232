<template>
    <v-dialog v-model="show" persistent width="800">
        <v-card>
            <v-card-title class="headline">{{ edit ? 'CẬP NHẬT ĐĂNG KÝ NGHỈ' : 'ĐĂNG KÝ LỊCH NGHỈ' }}</v-card-title>
            <br />
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                <v-icon size="30" color="green darken-2">
                                    mdi-account
                                </v-icon>
                                <div class="pl-2">
                                    <div class="title">Nhân viên</div>
                                </div>
                            </div>
                            <v-autocomplete :disabled="edit" v-model="form.users" :items="people" chips item-text="name" item-value="id"
                                multiple single-line :filter="fullTextFilter" ref="peoples" @blur="
                                    () => {
                                        filterLength = 0;
                                    }
                                ">
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < 2" v-bind="item.attrs" :input-value="item.selected" close
                                        @click:close="remove(item)">
                                        <v-avatar left color="indigo">
                                            <img v-if="item.url_image" :src="imageEndpoint + item.url_image" alt="ManhLe" />
                                            <span style="color: white" v-else-if="item && item.name">{{
                                                item.name.charAt(0).toUpperCase()
                                            }}</span>
                                            <v-icon v-else dark>mdi-account</v-icon>
                                        </v-avatar>
                                        {{ item.name }}
                                    </v-chip>
                                    <div v-if="index === 2">
                                        <v-chip>
                                            <b class="pr-1">+{{ form.users.length - 2 }} </b> Người
                                            khác</v-chip>
                                    </div>
                                </template>

                                <template v-slot:prepend-item>
                                    <v-layout class="pt-2">
                                        <div>
                                            <v-list-item ripple @click="selectAllUser">
                                                <v-list-item-action>
                                                    <v-icon :color="
                                                        form.users.length > 0 ? 'indigo darken-4' : ''
                                                    ">
                                                        {{
                                                            form.users.length == people.length ||
                                                            (form.users.length == filterLength &&
                                                                filterLength != 0)
                                                            ? "mdi-close-box"
                                                            : "mdi-checkbox-blank-outline"
                                                        }}
                                                    </v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title> Chọn tất cả </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <!-- <div style="width: 40%" class="pr-4">
                                                                        <v-select v-model="nhom_id" :items="nhoms" placeholder="Lọc theo nhóm" dense
                                                                            item-text="name" item-value="id" clearable @change="filterGroup">
                                                                        </v-select>
                                                                    </div> -->
                                    </v-layout>

                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-avatar color="indigo">
                                            <img v-if="data.item.url_image" :src="imageEndpoint + data.item.url_image"
                                                alt="ManhLe" />
                                            <span style="color: white" v-else-if="data.item && data.item.name">{{
                                                data.item.name.charAt(0).toUpperCase() }}</span>
                                            <v-icon v-else dark>mdi-account</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ data.item.email }} -
                                                {{
                                                    data.item.so_dien_thoai
                                                    ? "SĐT: " + data.item.so_dien_thoai
                                                    : ""
                                                }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>

                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex mb-3">
                                <v-icon size="30" color="green darken-2">
                                    mdi-help-circle
                                </v-icon>
                                <div class="pl-2">
                                    <div class="title">Loại nghỉ phép</div>
                                </div>
                            </div>

                            <v-select v-model="form.loai_nghi_phep" :items="loaiNghiPhep" item-text="name" item-value="code"
                                outlined></v-select>
                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex">
                                <v-icon size="30" color="green darken-2">
                                    mdi-calendar-clock
                                </v-icon>
                                <div class="pl-2">
                                    <div class="title">Thời gian xin nghỉ</div>
                                </div>
                            </div>
                            <v-row class="mt-2">
                                <v-col cols="6">
                                    <div class="label-form">Thời gian bắt đầu</div>
                                    <v-text-field type="datetime-local" v-model="form.bat_dau"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <div class="label-form">Thời gian kết thúc</div>
                                    <v-text-field type="datetime-local" v-model="form.ket_thuc"></v-text-field>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex mb-3">
                                <v-icon size="30" color="green darken-2">
                                    mdi-help-circle
                                </v-icon>
                                <div class="pl-2">
                                    <div class="title">Lý do</div>
                                </div>
                            </div>

                            <v-text-field outlined v-model="form.ly_do_nghi"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="pr-8 pb-3">
                <v-spacer></v-spacer>
                <v-btn text @click="show = false" class="mr-6">Đóng</v-btn>
                <div>
                    <v-btn v-if="!edit" :loading="btnLoading" color="success" @click="themMoi">
                        <v-icon left>mdi-plus</v-icon>
                        ĐĂNG KÝ
                    </v-btn>
                    <v-btn v-else :loading="btnLoading" color="success" @click="capNhat">
                        <v-icon left>mdi-check</v-icon>
                        Cập nhật
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { fullTextFilter } from "../../../utils/stringHelper";
import { TRANG_THAI, dangKyNghiWeb, editDangKyNghiWeb } from "@/api/dangkynghi";
import { getNhom, getAllPeople } from "@/api/nhom";

export default {
    data: () => ({
        TRANG_THAI,
        imageEndpoint: process.env.VUE_APP_BASE,
        show: false,
        edit: false,
        fullTextFilter,
        dates: [],
        people: [],
        btnLoading: false,
        form: {
            users: [],
            bat_dau: null,
            ket_thuc: null,
            ly_do_nghi: null,
            loai_nghi_phep: 'KHONG_PHEP'
        },
        dataPeople: [],
        filterLength: 0,
        nhoms: [],
        nhom_id: null,
        nameRules: [(v) => !!v || "Nhân viên không thể bỏ trống"],
        loaiNghiPhep: [
            { name: 'Cắt phép', code: 'CO_PHEP' },
            { name: 'Nghỉ lễ', code: 'NGHI_LE' },
            { name: 'Không cắt phép', code: 'KHONG_PHEP' }
        ]
    }),
    mounted() {
        this.getNguoiDung();
        this.getDSNhom();
    },
    watch: {
        dates(val) {
            if (val && val.length == 2) {
                if (new Date(val[0]) < new Date(val[1])) {
                    this.form.ngay_bat_dau = new Date(val[0]);
                    this.form.ngay_ket_thuc = new Date(val[1]);
                } else {
                    this.form.ngay_bat_dau = new Date(val[1]);
                    this.form.ngay_ket_thuc = new Date(val[0]);
                }
            } else {
                this.form.ngay_bat_dau = null;
                this.form.ngay_ket_thuc = null;
            }
        },
    },

    methods: {
        async getDSNhom() {
            let data = await getNhom({
                perPage: 9999,
            });
            this.nhoms = data.data;
        },
        async getNguoiDung() {
            let data = await getAllPeople();
            this.people = data;
            this.dataPeople = [...this.people];
        },
        showFormAdd() {
            this.show = true;
            this.edit = false;
            this.form = {
                users: [],
                bat_dau: null,
                ket_thuc: null,
                ly_do_nghi: null,
                loai_nghi_phep: 'KHONG_PHEP'
            };
        },
        formatDate(e) {
            if (!e) return null;
            try {
                let date = new Date(e);
                let gio =
                    date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                let phut =
                    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                let thang =
                    Number(date.getMonth() + 1) < 10
                        ? "0" + Number(date.getMonth() + 1)
                        : Number(date.getMonth() + 1);
                return gio + "h " + phut + ' Ngày ' + ngay + "/" + thang + "/" + date.getFullYear();
            } catch (error) {
                return "";
            }
        },
        async showFormEdit(data) {
            this.edit = true;
            this.show = true;
            this.form = {
                users: [data.nguoi_gui.user_id],
                loai_nghi_phep: data.loai,
                ...data,
            };
        },
        selectAllUser() {
            const filterPeople = this.$refs.peoples.filteredItems;
            this.filterLength = filterPeople.length;
            this.$nextTick(() => {
                if (this.form.users.length == filterPeople.length) {
                    this.form.users = [];
                } else {
                    this.form.users = [];
                    filterPeople.forEach((el) => {
                        this.form.users.push(el.id);
                    });
                }
            });
        },
        async themMoi() {
            this.btnLoading = true;
            try {
                await dangKyNghiWeb(this.form);
                this.show = false;
                this.btnLoading = false;
                this.$emit("on-done");
                this.$toast.info("Đăng ký thành công", {
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                });
            } catch (error) {
                console.log(error)
                this.btnLoading = false;
            }
        },
        async capNhat() {
            this.btnLoading = true;
            try {
                await editDangKyNghiWeb(this.form);
                this.show = false;
                this.btnLoading = false;
                this.$emit("on-done");
                this.$toast.info("Cập nhật thành công", {
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                });
            } catch (error) {
                console.log(error)
                this.btnLoading = false;
            }
        }

    },
};
</script>
  